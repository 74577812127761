import {
  IonButton,
  IonHeader,
  IonItem,
  IonLabel,
  IonMenu,
  IonMenuToggle,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import { authClient } from "../../firebase/client";
import { VisitProgressSection } from "./VisitProgressSection";

export const MENU_GESTURE_WIDTH = 15;
export const AppMenu = ({ currentUser, visits }) => {
  return (
    <IonMenu
      menuId="main-menu"
      contentId="main-content"
      side="end"
      maxEdgeStart={MENU_GESTURE_WIDTH}
    >
      <IonHeader>
        <IonToolbar>
          <IonTitle>YSP Explore</IonTitle>
          <IonMenuToggle slot="end">
            <IonButton fill="clear">Close</IonButton>
          </IonMenuToggle>
        </IonToolbar>
      </IonHeader>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          height: "100%",
        }}
      >
        <div style={{ flexGrow: 1 }}>
          {currentUser && (
            <>
              <div
                style={{
                  padding: 16,
                  backgroundColor: "hsl(214, 20%, 93%)",
                  fontSize: "0.8rem",
                  color: "hsl(214, 20%, 40%)",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  whiteSpace: "nowrap",
                }}
              >
                {currentUser?.email}
              </div>
              <IonItem
                routerLink="/visits"
                aria-label="Your visits"
                style={{ "--padding-start": 0 }}
              >
                <IonLabel style={{ paddingLeft: 16 }}>Your visits</IonLabel>
              </IonItem>
              <IonItem
                routerLink="/settings"
                aria-label="Settings"
                style={{ "--padding-start": 0 }}
              >
                <IonLabel style={{ paddingLeft: 16 }}>Settings</IonLabel>
              </IonItem>
              <IonItem
                aria-label="Sign out"
                style={{ "--padding-start": 0 }}
                onClick={authClient.signOut}
                button
              >
                <IonLabel style={{ paddingLeft: 16 }}>Sign out</IonLabel>
              </IonItem>
            </>
          )}
          {!currentUser && (
            <>
              <IonItem
                routerLink="/signup"
                aria-label="Sign up"
                style={{ "--padding-start": 0 }}
              >
                <IonLabel style={{ paddingLeft: 16 }}>Sign up</IonLabel>
              </IonItem>
              <IonItem
                routerLink="/login"
                aria-label="Log in"
                style={{ "--padding-start": 0 }}
              >
                <IonLabel style={{ paddingLeft: 16 }}>Log in</IonLabel>
              </IonItem>
            </>
          )}
        </div>
        {currentUser && visits && <VisitProgressSection visits={visits} />}
        <div style={{ padding: 16 }}>
          <span style={{ color: "hsl(214, 20%, 50%)" }}>
            Questions / feedback?{" "}
          </span>{" "}
          <a
            href="mailto:joejknowles@gmail.com"
            style={{ whiteSpace: "nowrap" }}
          >
            Contact us
          </a>
        </div>
      </div>
    </IonMenu>
  );
};
