import { useHelper, useTexture } from "@react-three/drei";
import { OrbitControls } from "../../threejsMods/OrbitControlsDrei";
import { MapControls } from "../../threejsMods/MapControlsDrei";
import { useEffect, useRef, useState } from "react";
import { DirectionalLightHelper, DoubleSide, Vector3 } from "three";
import { currentSculptures } from "../../data/sculptures";
import { SculpturePin } from "./SculpturePin";
import { DevTools } from "./DevTools";
import { UserAvatarPin } from "./UserAvatarPin";
import { Device } from "@capacitor/device";
import { useThree } from "@react-three/fiber";
import { usePrevious } from "../../utils/usePrevious";

const Map = ({
  setModalContent,
  modalContent,
  userCurrentCoordinates,
  userDirection,
  cameraControls,
  visits,
  nearbySculptures,
}) => {
  const mapPlaneRef = useRef();
  const [isMobile, setIsMobile] = useState();
  const prevCameraControls = usePrevious(cameraControls);

  const texture = useTexture("ysp-map-2.png");
  const directionalLight = useRef();

  const { camera } = useThree();

  useHelper(directionalLight, DirectionalLightHelper, 1);

  const sunPosition = new Vector3(1, 2, 3);
  sunPosition.setFromSphericalCoords(1000, Math.PI / 2, Math.PI / 12);

  useEffect(() => {
    Device.getInfo().then((info) => {
      if (
        info.operatingSystem === "ios" ||
        info.operatingSystem === "android"
      ) {
        setIsMobile(true);
      }
    });
  }, []);

  useEffect(() => {
    if (prevCameraControls !== cameraControls) {
      if (cameraControls === "orbit") {
        camera.position.set(
          userCurrentCoordinates[0],
          userCurrentCoordinates[1],
          userCurrentCoordinates[2] + 1
        );
      } else {
        camera.position.set(0, 20, 0);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cameraControls, prevCameraControls]);

  return (
    <>
      <DevTools {...{ mapPlaneRef }} />

      <directionalLight position={[1, 1, 0]} intensity={0.8} />
      <ambientLight intensity={0.2} />
      {cameraControls === "map" && (
        <MapControls
          makeDefault
          enablePan={true}
          maxPolarAngle={Math.PI / 2 - 0.3}
          screenSpacePanning={false}
          maxAzimuthAngle={isMobile ? 0 : undefined}
          minAzimuthAngle={isMobile ? 0 : undefined}
          minDistance={1.7}
          maxDistance={40}
          dampingFactor={0.04}
          target={[0, 0, 0]}
          position={[0, 20, 0]}
        />
      )}
      {cameraControls === "orbit" && (
        <OrbitControls
          makeDefault
          minDistance={1.2}
          maxDistance={5}
          target={[userCurrentCoordinates[0], 0.4, userCurrentCoordinates[2]]}
          maxPolarAngle={Math.PI / 2 - 0.2}
          minPolarAngle={Math.PI / 2 - 0.2}
          enablePan={false}
        />
      )}
      <mesh
        position-y={0}
        rotation-x={-Math.PI * 0.5}
        scale={20}
        receiveShadow
        ref={mapPlaneRef}
      >
        <planeGeometry />
        <meshStandardMaterial map={texture} side={DoubleSide} />
      </mesh>
      {currentSculptures.map((sculpture) => (
        <SculpturePin
          key={sculpture.id}
          sculpture={sculpture}
          isOpen={modalContent?.id === sculpture.id}
          hasVisited={visits?.some(
            (visit) => visit.sculptureId === sculpture.id
          )}
          isNearby={nearbySculptures[sculpture.id]}
          {...{ setModalContent }}
        />
      ))}
      {userCurrentCoordinates && (
        <UserAvatarPin
          position={userCurrentCoordinates}
          direction={userDirection}
        />
      )}
    </>
  );
};

export { Map };
