import { useEffect, useRef, useState } from "react";
import { useNearbySculptures } from "./useNearbySculptures";

const MIN_VISIT_IN_SECONDS = 15;
const MIN_VISIT_IN_MS = MIN_VISIT_IN_SECONDS * 1000;

const MIN_EXIT_BETWEEN_VISITS_IN_MINUTES = 15;
const MIN_TIME_AWAY_BETWEEN_VISITS_IN_MS =
  MIN_EXIT_BETWEEN_VISITS_IN_MINUTES * 1000 * 60;

const CONTACT_ACTIONS = {
  ENTER: "ENTER",
  LEAVE: "LEAVE",
};

export const useTrackSculptureVisits = (userLocation, sculptures, onVisit) => {
  const nearbySculptures = useNearbySculptures(userLocation, sculptures);
  const [sculptureContactHistory, setSculptureContactHistory] = useState({});
  const [recentlyVisitedSculptures, setRecentlyVisitedSculptures] = useState(
    {}
  );
  const timeouts = useRef({});

  useEffect(() => {
    setSculptureContactHistory(
      Object.entries(nearbySculptures).reduce(
        (result, [sculptureIdString, isNear]) => {
          const sculptureId = parseInt(sculptureIdString);
          const lastAction = sculptureContactHistory[sculptureId]?.lastAction;
          if (
            isNear &&
            (!lastAction ||
              (lastAction === CONTACT_ACTIONS.LEAVE &&
                !recentlyVisitedSculptures[sculptureId]))
          ) {
            const enteredAt = Date.now();
            result[sculptureId] = {
              lastAction: CONTACT_ACTIONS.ENTER,
              lastActionAt: enteredAt,
            };
            clearTimeout(timeouts.current[sculptureId]);
            timeouts.current[sculptureId] = setTimeout(() => {
              onVisit(sculptureId);
              setRecentlyVisitedSculptures((prev) => ({
                ...prev,
                [sculptureId]: true,
              }));
            }, MIN_VISIT_IN_MS);
          } else if (!isNear && lastAction === CONTACT_ACTIONS.ENTER) {
            result[sculptureId] = {
              lastAction: CONTACT_ACTIONS.LEAVE,
              lastActionAt: Date.now(),
            };
            clearTimeout(timeouts.current[sculptureId]);
            timeouts.current[sculptureId] = setTimeout(() => {
              setRecentlyVisitedSculptures((prev) => ({
                ...prev,
                [sculptureId]: false,
              }));
            }, MIN_TIME_AWAY_BETWEEN_VISITS_IN_MS);
          }
          return result;
        },
        sculptureContactHistory
      )
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(nearbySculptures)]);

  return nearbySculptures;
};
