import { toast } from "react-toastify";
import { ToastContainer as ToastifyContainer } from "react-toastify";
import { createGlobalStyle } from "styled-components";

const GlobalStyles = createGlobalStyle`
  .Toastify__toast {
    width: 100vw;
    cursor: pointer;
  }
  .Toastify__toast + .Toastify__toast {
    margin-top: 4px !important;
  }
  @media screen and (min-width: 481px) {
    .Toastify__toast {
      width: 400px;
    }
    .Toastify__toast-container--bottom-center {
      margin-left: -50px;
    }
  }
`;

export const ToastContainer = () => (
  <>
    <GlobalStyles />
    <ToastifyContainer position="bottom-center" />
  </>
);

export function newVisitToast(sculpture, onToastClick) {
  toast(
    <div
      style={{
        display: "flex",
        fontFamily: "var(--ion-font-family, inherit)",
      }}
    >
      <img
        src={sculpture.image}
        style={{
          minHeight: 90,
          maxWidth: "40%",
          margin: "-16px",
          marginRight: 12,
          objectFit: "cover",
        }}
        alt={`${sculpture.title} by ${sculpture.by}`}
      />
      <div>
        <div style={{ fontSize: 14, color: "hsl(214, 20%, 55%)" }}>
          Visit saved
        </div>
        <div
          style={{
            fontSize: 20,
            marginTop: 6,
            fontWeight: 500,
            color: "hsl(214, 20%, 30%)",
          }}
        >
          {sculpture.title}
        </div>
      </div>
    </div>,
    {
      onClick: onToastClick,
      closeOnClick: false,
      hideProgressBar: true,
      style: {
        boxShadow: "hsla(214, 10%, 50%, 0.9) 0px 0px 15px",
      },
      autoClose: 10 * 1000,
    }
  );
}
