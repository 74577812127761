import {
  IonBackButton,
  IonContent,
  IonHeader,
  IonPage,
  IonTitle,
  IonToolbar,
  useIonLoading,
} from "@ionic/react";
import { Button, TextField } from "@mui/material";
import { useState } from "react";
import { Link } from "react-router-dom";
import { authClient } from "../../firebase/client";

export function LoginPage() {
  const [error, setError] = useState();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const [showLoading, hideLoading] = useIonLoading();
  const handleLogin = async (e) => {
    e.preventDefault();
    await showLoading();
    try {
      await authClient.logIn({ email, password });
    } catch (e) {
      setError("Sorry, something went wrong");
    } finally {
      await hideLoading();
    }
  };
  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <div style={{ display: "flex" }}>
            <IonBackButton defaultHref="/" />
            <IonTitle>Log in</IonTitle>
          </div>
        </IonToolbar>
      </IonHeader>

      <IonContent>
        <form onSubmit={handleLogin}>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              padding: "100px 12px",
            }}
          >
            <div
              style={{
                display: "flex",
                gap: 16,
                flexDirection: "column",
                width: "100%",
                maxWidth: 350,
              }}
            >
              <TextField
                label="Email"
                value={email}
                name="email"
                type="email"
                onChange={(e) => setEmail(e.target.value ?? "")}
              />
              <TextField
                value={password}
                name="password"
                onIonChange={(e) => setPassword(e.detail.value ?? "")}
                type="password"
                label="Password"
                onChange={(e) => setPassword(e.target.value ?? "")}
              />
              {error && (
                <div
                  style={{ color: "hsl(17, 60%, 45%)", textAlign: "center" }}
                >
                  {error}
                </div>
              )}
              <Button type="submit">Log in</Button>
            </div>
          </div>
          <div style={{ textAlign: "center", marginTop: 16 }}>
            <span style={{ color: "hsl(214, 20%, 50%)" }}>
              Need an account?
            </span>{" "}
            <Link to="/signup">Sign up</Link>
          </div>
        </form>
      </IonContent>
    </IonPage>
  );
}
