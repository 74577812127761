import { IonItem, IonLabel } from "@ionic/react";
import { currentSculptures } from "../../data/sculptures";
import styled from "styled-components";

const ProgressBar = styled.progress`
  width: 100%;
  height: 5px;
  margin-top: 4px;
  border-radius: 2px;

  &::-webkit-progress-bar {
    background-color: hsl(110, 40%, 93%);
    border-radius: 2px;
  }

  &::-webkit-progress-value {
    background-color: hsl(110, 60%, 75%);
    border-radius: 2px;
  }
`;

export const VisitProgressSection = ({ visits }) => {
  const visitsForCurrentSculptures = currentSculptures.filter((sculpture) =>
    visits.find((visit) => visit.sculptureId === sculpture.id)
  ).length;
  return (
    <IonItem
      routerLink="/visits"
      aria-label="Your visits"
      style={{
        "--padding-start": 0,
        "--inner-border-width": "1px 0",
      }}
    >
      <IonLabel style={{ paddingLeft: 16 }}>
        <div>
          {visitsForCurrentSculptures} / {currentSculptures.length} sculptures
          visited
        </div>
        <div>
          <ProgressBar
            value={visitsForCurrentSculptures}
            max={currentSculptures.length}
          />
        </div>
      </IonLabel>
    </IonItem>
  );
};
