import { useContext, useEffect, useState } from "react";

export const creatLocalStorageProvider =
  (context, key, initialValue) =>
  ({ children }) => {
    const [storedValue, setStoredValue] = useState(() => {
      try {
        const item = window.localStorage.getItem(key);
        return item ? JSON.parse(item) : initialValue;
      } catch (error) {
        return initialValue;
      }
    });

    useEffect(() => {
      try {
        window.localStorage.setItem(key, JSON.stringify(storedValue));
      } catch (error) {
        console.error("Could not store value:", error);
      }
    }, [storedValue]);

    return (
      <context.Provider value={[storedValue, setStoredValue]}>
        {children}
      </context.Provider>
    );
  };

export const createUseContextHook = (context) => () => {
  const contextValue = useContext(context);
  if (!contextValue) {
    throw new Error("Must be used within a Provider with the correct context");
  }
  return contextValue;
};
