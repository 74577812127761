import { useFrame } from "@react-three/fiber";
import { useRef } from "react";
import { useVisitsStore } from "../../data/stores/visitsStore";
import tinyColour from "tinycolor2";

const colors = {
  default: {
    default: "hsl(204, 60%, 35%)",
    highlighted: "hsl(204, 70%, 38%)",
  },
  open: {
    default: "hsl(170, 70%, 60%)",
    highlighted: "hsl(160, 80%, 63%)",
  },
  visited: {
    default: "hsl(150, 80%, 40%)",
    highlighted: "hsl(150, 70%, 45%)",
  },
  locationUncertain: {
    default: "hsl(204, 80%, 25%)",
    highlighted: "hsl(204, 90%, 28%)",
  },
};

const SculpturePin = ({ sculpture, setModalContent, isOpen, isNearby }) => {
  const lastVisitedAt = useVisitsStore((state) =>
    state.visits
      // eslint-disable-next-line eqeqeq
      ?.find((visit) => visit.sculptureId == sculpture.id)
      ?.createdAt?.toDate()
  );
  const meshRef = useRef();

  let currentColour = colors.default;
  if (isOpen) {
    currentColour = colors.open;
  } else if (lastVisitedAt) {
    const ONE_YEAR = 31536000000;
    const MAX_PERCENT_OF_DEFAULT_COLOR = 0.6;
    const MAX_VISIT_AGE_TO_SHOW = ONE_YEAR / 12 / MAX_PERCENT_OF_DEFAULT_COLOR;
    const percentageOfDefaultColourToMix = Math.min(
      (new Date() - new Date(lastVisitedAt)) / MAX_VISIT_AGE_TO_SHOW,
      MAX_PERCENT_OF_DEFAULT_COLOR
    );
    currentColour = {
      default: tinyColour
        .mix(
          colors.visited.default,
          colors.default.default,
          100 * percentageOfDefaultColourToMix
        )
        .toString(),
      highlighted: colors.visited.highlighted,
    };
  } else if (sculpture.locationUncertain) {
    currentColour = colors.locationUncertain;
  }

  useFrame(({ clock }) => {
    // make the color pulse with NEARBY_COLOUR
    if (isNearby) {
      // alternate between 100 and 170 every 1.2 seconds
      const tempColour =
        clock.getElapsedTime() % 2.6 < 1.3
          ? currentColour.highlighted
          : currentColour.default;
      meshRef.current?.material?.color?.set(tempColour);
    } else {
      meshRef.current?.material?.color?.set(currentColour.default);
    }
  });

  return (
    <mesh
      position={sculpture.position}
      scale={0.1}
      onClick={() => {
        // makes sure only the closest sculpture is opened
        setModalContent((prev) => prev || sculpture);
      }}
      ref={meshRef}
    >
      <sphereGeometry />
      <meshStandardMaterial color={currentColour.default} />
    </mesh>
  );
};

export { SculpturePin };
