import { Helmet } from "react-helmet";

export const OgMeta = ({ title, description, image }) => {
  return (
    <Helmet>
      <title>{title}</title>
      {description && <meta name="description" content={description} />}

      <meta property="og:title" content={title} />
      {description && <meta property="og:description" content={description} />}
      <meta property="og:url" content={window.location.href} />
      {image && (
        <meta property="og:image" content={"https://yspexplore.com" + image} />
      )}
      <meta property="og:type" content="website" />

      <meta name="twitter:card" content="summary_large_image" />
      <meta property="twitter:domain" content="yspexplore.com" />
      <meta property="twitter:url" content={window.location.href} />
      <meta name="twitter:title" content={title} />
      {description && <meta name="twitter:description" content={description} />}
      {image && (
        <meta name="twitter:image" content={"https://yspexplore.com" + image} />
      )}
    </Helmet>
  );
};
