import { useEffect, useMemo, useRef, useState } from "react";
import { getThreePositionFromGeolocation } from "./getThreePositionFromGeolocation";
import { Capacitor, registerPlugin } from "@capacitor/core";
import { App } from "@capacitor/app";
import { useShouldStartOnMapLocalStorage } from "../../SettingsPage/ShouldStartOnMapLocalStorageContext";

const BackgroundGeolocation = registerPlugin("BackgroundGeolocation");

const exampleMapStartPosition = [53.614419, -1.570578];
const fakeCoordinates = new URLSearchParams(window.location.search).has("fl")
  ? getThreePositionFromGeolocation(exampleMapStartPosition)
  : null;

const forceShowPerf = false;

export const useUserLocation = () => {
  const [userGeolocation, setUserGeolocation] = useState(null);
  const [userDirection, setUserDirection] = useState(null);
  const [isAppInFocus, setIsAppInFocus] = useState(true);
  const lastUserDirection = useRef(null);
  const initialUserGeoLocationRef = useRef(null);
  const globalGeolocationWatcherIdsRef = useRef([]);
  const shouldTrackLocationRef = useRef(null);

  const [shouldAdjustPositionToStartOnMap] = useShouldStartOnMapLocalStorage();

  useEffect(() => {
    if (userDirection) {
      lastUserDirection.current = userDirection;
    }
  }, [userDirection]);

  useEffect(() => {
    const appStateListener = App.addListener("appStateChange", (state) => {
      setIsAppInFocus(state.isActive);
    });
    return () => {
      appStateListener.then((listener) => listener.remove());
    };
  }, []);

  const adjustPositionToStartOnMap = (geolocation) => {
    if (!initialUserGeoLocationRef.current) {
      return [];
    }
    return [
      exampleMapStartPosition[0] -
        initialUserGeoLocationRef.current[0] +
        geolocation[0],
      exampleMapStartPosition[1] -
        initialUserGeoLocationRef.current[1] +
        geolocation[1],
    ];
  };

  const userGeolocationAfterAdjustment =
    userGeolocation && shouldAdjustPositionToStartOnMap
      ? adjustPositionToStartOnMap(userGeolocation)
      : userGeolocation;

  const userCurrentCoordinates = useMemo(
    () =>
      fakeCoordinates ||
      (userGeolocationAfterAdjustment &&
        getThreePositionFromGeolocation(userGeolocationAfterAdjustment)),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [JSON.stringify(userGeolocationAfterAdjustment)]
  );

  // when both x and y are between -20 and 20, the user is in the map region
  const isUserInMapRegion =
    userCurrentCoordinates &&
    userCurrentCoordinates[0] > -20 &&
    userCurrentCoordinates[0] < 20 &&
    userCurrentCoordinates[1] > -20 &&
    userCurrentCoordinates[1] < 20;

  shouldTrackLocationRef.current = isAppInFocus || isUserInMapRegion;

  useEffect(() => {
    if (Capacitor.isNativePlatform()) {
      if (
        (isAppInFocus || isUserInMapRegion) &&
        !globalGeolocationWatcherIdsRef.current[0]
      ) {
        BackgroundGeolocation.addWatcher(
          {
            backgroundMessage: "Cancel to prevent battery drain.",
            backgroundTitle: "Tracking You.",
            requestPermissions: true,
            stale: false,
            distanceFilter: 0,
          },
          function callback(location, error) {
            if (error) {
              if (error.code === "NOT_AUTHORIZED") {
                if (
                  window.confirm(
                    "This app needs your location, " +
                      "but does not have permission.\n\n" +
                      "Open settings now?"
                  )
                ) {
                  BackgroundGeolocation.openSettings();
                }
              }
              return console.error(error);
            }
            if (!initialUserGeoLocationRef.current) {
              initialUserGeoLocationRef.current = [
                location.latitude,
                location.longitude,
              ];
            }
            setUserGeolocation([location.latitude, location.longitude]);
            setUserDirection(
              location.bearing ?? lastUserDirection.current ?? 180
            );
          }
        ).then(function after_the_watcher_has_been_added(watcherId) {
          globalGeolocationWatcherIdsRef.current = [
            ...globalGeolocationWatcherIdsRef.current,
            watcherId,
          ];
          // hacky – not sure if needed
          if (
            globalGeolocationWatcherIdsRef.current[0] &&
            !shouldTrackLocationRef.current
          ) {
            globalGeolocationWatcherIdsRef.current.forEach((watcherId) => {
              BackgroundGeolocation.removeWatcher({
                id: watcherId,
              });
            });
            globalGeolocationWatcherIdsRef.current = [];
          }
        });
      }
      return () => {
        if (
          globalGeolocationWatcherIdsRef.current[0] &&
          !shouldTrackLocationRef.current
        ) {
          globalGeolocationWatcherIdsRef.current.forEach((watcherId) => {
            BackgroundGeolocation.removeWatcher({
              id: watcherId,
            });
          });
          globalGeolocationWatcherIdsRef.current = [];
        }
      };
    } else if (JSON.parse(localStorage.getItem("showPerf")) || forceShowPerf) {
      initialUserGeoLocationRef.current = exampleMapStartPosition;
      setUserGeolocation(exampleMapStartPosition);
      setUserDirection(210);
    }
  }, [isAppInFocus, isUserInMapRegion]);

  return {
    userCurrentCoordinates,
    userDirection,
    isUserInMapRegion,
    isRealLocation:
      !forceShowPerf &&
      !JSON.parse(localStorage.getItem("showPerf")) &&
      !shouldAdjustPositionToStartOnMap,
  };
};
