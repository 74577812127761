import { isNearSculpture } from "./isNearSculpture";

export const getSculpturesInRange = (userLocation, sculptures) => {
  if (!userLocation || sculptures.length === 0) return {};
  return sculptures.reduce(
    (result, sculpture) => ({
      ...result,
      [sculpture.id]: isNearSculpture(userLocation, sculpture.position),
    }),
    {}
  );
};
