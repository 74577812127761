import { sculptures } from "../../../data/sculptures";

const referenceLocations = {
  needle: sculptures.filter((s) => s.id === 18)[0],
  myth: sculptures.filter((s) => s.id === 3)[0],
  peterFold: sculptures.filter((s) => s.id === 20)[0],
};
// Create a function that solves y = mx + c for any given two points
const getLinearEquationConstants = (point1, point2) => {
  const [x1, y1] = point1;
  const [x2, y2] = point2;
  const multiplier = (y2 - y1) / (x2 - x1);
  const constant = y1 - multiplier * x1;
  return [multiplier, constant];
};
const threePositionMultiplierAndConstants = () => {
  const { needle, myth, peterFold } = referenceLocations;
  const [xMultiplier, xConstant] = getLinearEquationConstants(
    [needle.geolocation?.[1], needle.position[0]],
    [myth.geolocation?.[1], myth.position[0]]
  );
  const [yMultiplier, yConstant] = getLinearEquationConstants(
    [needle.geolocation?.[0], needle.position[2]],
    [peterFold.geolocation?.[0], peterFold.position[2]]
  );
  return {
    xMultiplier,
    xConstant,
    yMultiplier,
    yConstant,
  };
};
// Create a function that returns the threePosition for any given geolocation
export const getThreePositionFromGeolocation = (geolocation) => {
  const { xMultiplier, xConstant, yMultiplier, yConstant } =
    threePositionMultiplierAndConstants();
  return [
    geolocation[1] * xMultiplier + xConstant,
    0,
    geolocation[0] * yMultiplier + yConstant,
  ];
};
