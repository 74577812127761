import { createContext } from "react";
import {
  creatLocalStorageProvider,
  createUseContextHook,
} from "./creatLocalStorageProvider";

const ShouldStartOnMapLocalStorageContext = createContext();

export const ShouldStartOnMapLocalStorageProvider = creatLocalStorageProvider(
  ShouldStartOnMapLocalStorageContext,
  "shouldStartOnMap",
  false
);

export const useShouldStartOnMapLocalStorage = createUseContextHook(
  ShouldStartOnMapLocalStorageContext
);
