import styled from "styled-components";

const Root = styled.div`
  position: absolute;
  top: 6px;
  right: 6px;
  background-color: hsl(140, 90%, 60%);
  color: hsl(140, 60%, 20%);
  border-radius: 4px;
  padding: 4px 12px;
  font-size: 12px;
  letter-spacing: 0.5px;
`;

export const VisitedTag = () => <Root>VISITED</Root>;
