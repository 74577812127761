import { format, isToday } from "date-fns";
import styled from "styled-components";
import { sculptures } from "../../data/sculptures";
import { useVisitsStore } from "../../data/stores/visitsStore";

const NoStyleAnchor = styled.a`
  text-decoration: inherit;
  color: inherit;

  &:visited {
    text-decoration: inherit;
    color: inherit;
  }
`;

export const VisitsList = ({ sculptureId }) => {
  const { visits, visitsGroupedByDate } = useVisitsStore((state) => ({
    ...state,
    visitsGroupedByDate: Object.entries(
      state.visits?.reduce((acc, visit) => {
        // eslint-disable-next-line eqeqeq
        if (sculptureId && visit.sculptureId != sculptureId) return acc;

        const date = format(visit.createdAt.toDate(), "do LLL y");
        if (acc[date]) {
          acc[date].push(visit);
        } else {
          acc[date] = [visit];
        }
        return acc;
      }, {}) || {}
    ),
  }));

  return (
    <>
      {!visits && (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            marginTop: 60,
            color: "hsl(214, 20%, 60%)",
          }}
        >
          Loading...
        </div>
      )}
      {visitsGroupedByDate.map(([date, visitsOnDate]) => (
        <div
          key={date}
          style={{
            display: "flex",
            width: "100%",
            flexDirection: "column",
            alignItems: "center",
            padding: "16px 8px",
          }}
        >
          <div style={{ marginBottom: 8, color: "hsl(214, 20%, 30%)" }}>
            {date} {isToday(new Date(visitsOnDate[0].createdAt)) && "(Today)"}
          </div>
          {visitsOnDate?.map((visit) => {
            const visitedSculpture = sculptures.find(
              (sculpture) => sculpture.id === visit.sculptureId
            );
            return (
              <NoStyleAnchor
                key={visit.id}
                href={`/sculptures/${visitedSculpture.id}`}
                data-cy="visit-card"
                style={{
                  border: `1px solid hsl(214, 20%, 80%)`,
                  width: 400,
                  maxWidth: "100%",
                  marginTop: 8,
                  display: "flex",
                  minWidth: 0,
                  borderRadius: 4,
                  overflow: "hidden",
                  height: 80,
                  position: "relative",
                }}
              >
                <img
                  src={visitedSculpture.image}
                  style={{
                    // make smaller on narrow screens?
                    width: 120,
                    objectFit: "cover",
                    flexShrink: 0,
                  }}
                  alt={`${visitedSculpture.title}`}
                />
                <div
                  style={{
                    padding: 8,
                    minWidth: 0,
                  }}
                >
                  <div
                    style={{
                      color: "hsl(214, 20%, 30%)",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      whiteSpace: "nowrap",
                    }}
                  >
                    {visitedSculpture.title}
                  </div>
                  <div
                    style={{
                      color: "hsl(214, 20%, 60%)",
                      fontSize: "0.9rem",
                      marginTop: 4,
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      whiteSpace: "nowrap",
                    }}
                  >
                    By {visitedSculpture.by}
                  </div>
                  <div
                    style={{
                      color: "hsl(214, 20%, 60%)",
                      fontSize: "0.9rem",
                      position: "absolute",
                      bottom: 8,
                      right: 8,
                    }}
                  >
                    {format(visit.createdAt.toDate(), "HH:mm")}
                  </div>
                </div>
              </NoStyleAnchor>
            );
          })}
        </div>
      ))}
      {visits && Object.keys(visitsGroupedByDate).length === 0 && (
        <div
          style={{
            padding: (sculptureId ? "16px" : "150px") + " 16px",
            textAlign: "center",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <h2 style={{ color: "hsl(214, 20%, 40%)" }}>
            No visits recorded yet{sculptureId && " for this sculpture"}…
          </h2>
          <p style={{ color: "hsl(214, 20%, 50%)", maxWidth: 320 }}>
            Open the app while exploring the Sculpture Park – make sure you're
            logged in.
            <br />
            <br />
            Afterwards, you'll see a summary of your visits here.
          </p>
        </div>
      )}
    </>
  );
};
