import {
  IonBackButton,
  IonContent,
  IonHeader,
  IonPage,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import { Switch } from "@mui/material";
import { useShouldStartOnMapLocalStorage } from "./ShouldStartOnMapLocalStorageContext";

export const SettingsPage = () => {
  const [shouldStartOnMap, setShouldStartOnMap] =
    useShouldStartOnMapLocalStorage();
  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <div style={{ display: "flex" }}>
            <IonBackButton defaultHref="/" />
            <IonTitle>Settings</IonTitle>
          </div>
        </IonToolbar>
      </IonHeader>

      <IonContent>
        <div style={{ padding: 16 }}>
          <h2>Settings</h2>
          <div
            style={{ display: "flex", alignItems: "center", flexWrap: "wrap" }}
          >
            Pretend you're in the park
            <Switch
              checked={shouldStartOnMap}
              onChange={(event) => setShouldStartOnMap(event.target.checked)}
            />
            <span style={{ marginRight: 8 }} />
            <p
              style={{
                color: shouldStartOnMap
                  ? "hsl(214, 50%, 60%)"
                  : "hsl(214, 20%, 60%)",
                fontSize: "0.8rem",
                border: shouldStartOnMap
                  ? "1px solid hsl(214, 90%, 90%)"
                  : "1px solid hsl(214, 20%, 90%)",
                padding: 8,
                borderRadius: 4,
                width: "fit-content",
                margin: 0,
              }}
            >
              Sets your location to somewhere in the park, so you can test out
              the app without being there. (Mobile only)
            </p>
          </div>
        </div>
      </IonContent>
    </IonPage>
  );
};
