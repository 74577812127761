import * as SentryCapacitor from "@sentry/capacitor";
import * as SentryReact from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";
import React from "react";
import { createRoot } from "react-dom/client";
import App from "./App";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";
import reportWebVitals from "./reportWebVitals";

import "@fontsource/roboto/300.css";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/700.css";

if (!window.Cypress) {
  SentryCapacitor.init(
    {
      dsn: "https://1f2444b13f4a48a6a42d910b3820b1cc@o4504973146128384.ingest.sentry.io/4504973150453760",
      release: "ysp-explore@" + process.env.npm_package_version,
      dist: "1",
      // Set tracesSampleRate to 1.0 to capture 100% of transactions for performance monitoring.
      // We recommend adjusting this value in production.
      tracesSampleRate: 1.0,
      integrations: [
        new BrowserTracing({
          tracingOrigins: [
            "localhost",
            "https://yspexplore.com/api",
            "com.yspexplore.app",
          ],
        }),
      ],
      environment: process.env.NODE_ENV,
    },
    SentryReact.init
  );
}

const container = document.getElementById("root");
const root = createRoot(container);
root.render(
  <SentryReact.ErrorBoundary fallback={"An error has occurred"}>
    <App />
  </SentryReact.ErrorBoundary>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
