import { useFrame, useThree } from "@react-three/fiber";
import * as React from "react";
import { MapControls as MapControlsImpl } from "three-stdlib";
var __rest =
  (this && this.__rest) ||
  function (s, e) {
    var t = {};
    for (var p in s)
      if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
      // eslint-disable-next-line no-redeclare
      for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
        if (
          e.indexOf(p[i]) < 0 &&
          Object.prototype.propertyIsEnumerable.call(s, p[i])
        )
          t[p[i]] = s[p[i]];
      }
    return t;
  };
export const MapControls = React.forwardRef(
  (props = { enableDamping: true }, ref) => {
    const { domElement, camera, makeDefault, onChange, onStart, onEnd } = props,
      rest = __rest(props, [
        "domElement",
        "camera",
        "makeDefault",
        "onChange",
        "onStart",
        "onEnd",
      ]);
    const invalidate = useThree((state) => state.invalidate);
    const defaultCamera = useThree((state) => state.camera);
    const gl = useThree((state) => state.gl);
    const events = useThree((state) => state.events);
    const set = useThree((state) => state.set);
    const get = useThree((state) => state.get);
    const explDomElement = domElement || events.connected || gl.domElement;
    const explCamera = camera || defaultCamera;
    const controls = React.useMemo(
      () => new MapControlsImpl(explCamera),
      [explCamera]
    );
    React.useEffect(() => {
      controls.connect(explDomElement);
      const callback = (e) => {
        invalidate();
        if (onChange) onChange(e);
      };
      controls.addEventListener("change", callback);
      if (onStart) controls.addEventListener("start", onStart);
      if (onEnd) controls.addEventListener("end", onEnd);
      return () => {
        controls.dispose();
        controls.removeEventListener("change", callback);
        if (onStart) controls.removeEventListener("start", onStart);
        if (onEnd) controls.removeEventListener("end", onEnd);
      };
    }, [onChange, onStart, onEnd, controls, invalidate, explDomElement]);
    React.useEffect(() => {
      if (makeDefault) {
        const old = get().controls;
        set({ controls });
        return () => set({ controls: old });
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [makeDefault, controls]);
    useFrame(() => controls.update(), -1);
    return React.createElement(
      "primitive",
      Object.assign({ ref: ref, object: controls, enableDamping: true }, rest)
    );
  }
);
