import {
  IonBackButton,
  IonContent,
  IonHeader,
  IonPage,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import { VisitsList } from "./VisitsList";

export const VisitsPage = ({ currentUser }) => {
  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <div style={{ display: "flex" }}>
            <IonBackButton defaultHref="/" />
            <IonTitle>Your visits</IonTitle>
          </div>
        </IonToolbar>
      </IonHeader>

      <IonContent>
        <VisitsList currentUser={currentUser} />
      </IonContent>
    </IonPage>
  );
};
