import { IonApp, setupIonicReact } from "@ionic/react";
import { IonReactRouter } from "@ionic/react-router";
import { Redirect, Route } from "react-router-dom";
import MapTab from "./pages/MapTab";
import "react-toastify/dist/ReactToastify.css";

/* Core CSS required for Ionic components to work properly */
import "@ionic/react/css/core.css";

/* Basic CSS for apps built with Ionic */
import "@ionic/react/css/normalize.css";
import "@ionic/react/css/structure.css";
import "@ionic/react/css/typography.css";

/* Optional CSS utils that can be commented out */
import "@ionic/react/css/display.css";
import "@ionic/react/css/flex-utils.css";
import "@ionic/react/css/float-elements.css";
import "@ionic/react/css/padding.css";
import "@ionic/react/css/text-alignment.css";
import "@ionic/react/css/text-transformation.css";

/* Theme variables */
import { useEffect, useState } from "react";
import { OgMeta } from "./components/OgMeta";
import { authClient } from "./firebase/client";
import { SculpturePage } from "./pages/SculpturePage/SculpturePage";
import { VisitsPage } from "./pages/VisitsPage/VisitsPage";
import { LoginPage } from "./pages/auth/Login";
import { SignUpPage } from "./pages/auth/SignUp";
import "./theme/variables.css";
import { SettingsPage } from "./pages/SettingsPage/SettingsPage";
import { ShouldStartOnMapLocalStorageProvider } from "./pages/SettingsPage/ShouldStartOnMapLocalStorageContext";
import { ToastContainer } from "./pages/MapTab/newVisitToast";

setupIonicReact();

const App = () => {
  const [currentUser, setCurrentUser] = useState(null);
  const [isAuthLoaded, setIsAuthLoaded] = useState(false);
  useEffect(() => {
    const stopWatching = authClient.watchAuthState((user) => {
      setIsAuthLoaded(true);
      setCurrentUser(user);
    });
    return () => {
      stopWatching();
    };
  }, []);

  return (
    <IonApp>
      <OgMeta
        title="YSP Explore | Yorkshire Sculpture Park"
        description="Location based guide to YSP. Track your visits and learn about the art here."
        image="/ysp-screenshot.png"
      />
      <ShouldStartOnMapLocalStorageProvider>
        <IonReactRouter>
          <Route path="/map">
            <Redirect to="/" />
          </Route>
          <Route path="/">
            {isAuthLoaded && <MapTab {...{ currentUser }} />}
          </Route>
          <Route exact path="/login">
            {isAuthLoaded && (
              <>
                {!currentUser && <LoginPage />}
                {currentUser && <Redirect to="/" />}
              </>
            )}
          </Route>
          <Route exact path="/signup">
            {isAuthLoaded && (
              <>
                {!currentUser && <SignUpPage />}
                {currentUser && <Redirect to="/" />}
              </>
            )}
          </Route>
          <Route exact path="/visits">
            {isAuthLoaded && (
              <>
                {currentUser && <VisitsPage {...{ currentUser }} />}
                {!currentUser && <Redirect to="/login" />}
              </>
            )}
          </Route>
          <Route exact path="/settings">
            {isAuthLoaded && <SettingsPage />}
          </Route>
          <Route
            exact
            path="/sculptures/:sculptureId"
            render={({ match }) => (
              <SculpturePage
                sculptureId={match?.params?.sculptureId}
                currentUser={currentUser}
              />
            )}
          />
          <ToastContainer />
        </IonReactRouter>
      </ShouldStartOnMapLocalStorageProvider>
    </IonApp>
  );
};

export default App;
