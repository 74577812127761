import { IonModal } from "@ionic/react";
import { Link } from "react-router-dom";
import { VisitedTag } from "./VisitedTag";

export const SculptureModal = ({
  setModalContent,
  modalContent,
  lastVisit,
}) => {
  return (
    <IonModal
      isOpen={true}
      style={{
        "--width": "80%",
        "--max-width": "500px",
        "--height": "fit-content",
        "--max-height": "fit-content",
        "--border-radius": "8px",
      }}
      onWillDismiss={() => setModalContent(null)}
    >
      <img
        src={modalContent.image}
        alt={`${modalContent.title} by ${modalContent.by}`}
        style={{ maxHeight: "50vh" }}
      />
      {lastVisit && <VisitedTag />}
      <div style={{ padding: "1rem" }}>
        <h1 style={{ margin: 0, fontSize: 18, lineHeight: "24px" }}>
          {modalContent.title}
          <span style={{ color: "hsl(214, 20%, 55%)", marginLeft: 8 }}>
            (
            {modalContent.createdAt.type === "year"
              ? modalContent.createdAt.year
              : `${modalContent.createdAt.from} – ${modalContent.createdAt.to}`}
            )
          </span>
        </h1>
        <div style={{ marginTop: 6 }}>by {modalContent.by}</div>
        <div style={{ textAlign: "right" }}>
          <Link to={`/sculptures/${modalContent.id}`}>More info</Link>
        </div>
      </div>
    </IonModal>
  );
};
