import {
  IonBackButton,
  IonContent,
  IonHeader,
  IonPage,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import { sculptures } from "../../data/sculptures";
import styled from "styled-components";
import { OgMeta } from "../../components/OgMeta";
import { VisitedTag } from "../MapTab/VisitedTag";
import { Tab, Tabs } from "@mui/material";
import { Box } from "@react-three/drei";
import { useState } from "react";
import { VisitsList } from "../VisitsPage/VisitsList";

const Root = styled.div`
  display: flex;
  justify-content: center;

  @media (min-width: 788px) {
    padding-top: 32px;
  }

  @media (max-width: 787px) {
    padding-top: calc((100vw - 720px) / 2);
  }
`;

const Content = styled.div`
  max-width: 720px;
`;

const ImageContainer = styled.div`
  text-align: center;
  overflow: hidden;
  background-color: hsl(214, 50%, 95%);
  box-shadow: 0 0 5px 2px inset hsl(214, 50%, 90%);
  box-sizing: border-box;
  position: relative;
`;

const Image = styled.img`
  max-height: 65vh;
  box-sizing: border-box;
  display: block;
  margin: auto;
`;

export const SculpturePage = ({ sculptureId, currentUser }) => {
  const [currentTab, setCurrentTab] = useState("summary");

  // eslint-disable-next-line eqeqeq
  const sculpture = sculptures.find((sculpture) => sculpture.id == sculptureId);

  if (!sculpture) {
    return <div>Sculpture not found</div>;
  }

  return (
    <IonPage>
      <OgMeta
        title={`${sculpture.title} by ${sculpture.by} | YSP Explore`}
        description={sculpture.info?.slice(0, 100)}
        image={sculpture.image}
      />
      <IonHeader>
        <IonToolbar>
          <div style={{ display: "flex" }}>
            <IonBackButton defaultHref="/" />
            <IonTitle>{sculpture.title}</IonTitle>
          </div>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <Root>
          <Content>
            <ImageContainer>
              <Image
                src={sculpture.image}
                alt={`${sculpture.title} by ${sculpture.by}`}
              />
              <VisitedTag />
            </ImageContainer>

            <div style={{ padding: "1rem" }}>
              <h1 style={{ margin: 0, fontSize: 18, lineHeight: "24px" }}>
                {sculpture.title}
                <span style={{ color: "hsl(214, 20%, 55%)", marginLeft: 8 }}>
                  (
                  {sculpture.createdAt.type === "year"
                    ? sculpture.createdAt.year
                    : `${sculpture.createdAt.from} – ${sculpture.createdAt.to}`}
                  )
                </span>
              </h1>
              <div style={{ marginTop: 6, display: "flex" }}>
                <div style={{ flexGrow: 1 }}>by {sculpture.by}</div>
              </div>

              <Box
                sx={{
                  borderBottom: 1,
                  borderColor: "divider",
                }}
                style={{
                  display: "flex",
                  marginTop: 16,
                }}
              >
                <Tabs
                  value={currentTab}
                  onChange={(_event, newValue) => setCurrentTab(newValue)}
                  aria-label="Sculpture tabs"
                >
                  <Tab label="Summary" value="summary" />
                  <Tab label="Your visits" value="visits" />
                </Tabs>
              </Box>
              {currentTab === "summary" && (
                <p style={{ whiteSpace: "pre-wrap" }}>
                  {sculpture.info || "More info coming soon!"}
                </p>
              )}
              {currentTab === "visits" && (
                <VisitsList
                  currentUser={currentUser}
                  sculptureId={sculptureId}
                />
              )}
            </div>
          </Content>
        </Root>
      </IonContent>
    </IonPage>
  );
};
