import { initializeApp } from "firebase/app";
import {
  onSnapshot,
  collection,
  getFirestore,
  addDoc,
  query,
  orderBy,
  doc,
  setDoc,
} from "firebase/firestore";
import {
  createUserWithEmailAndPassword,
  getAuth,
  onAuthStateChanged,
  signInWithEmailAndPassword,
  signOut,
} from "firebase/auth";

import { getAnalytics } from "firebase/analytics";

const firebaseConfig = {
  apiKey: "AIzaSyCxdT9VuP355Ml5w9Pr2OnDh9s9HGKAReU",
  authDomain: "ysp-explore.firebaseapp.com",
  projectId: "ysp-explore",
  storageBucket: "ysp-explore.appspot.com",
  messagingSenderId: "1017529956466",
  appId: "1:1017529956466:web:a7abbd868a77e0d2fdeaeb",
  measurementId: "G-P0R4GWP20Y",
};

const app = initializeApp(firebaseConfig);
// eslint-disable-next-line no-unused-vars
const analytics = getAnalytics(app);

const auth = getAuth(app);

export const authClient = {
  signUp: async ({ email, password }) => {
    const { user } = await createUserWithEmailAndPassword(
      auth,
      email,
      password
    );

    const db = getFirestore(app);
    await setDoc(doc(db, `users/${user.uid}`), {
      email,
    });

    return user;
  },
  logIn: async ({ email, password }) => {
    const { user } = await signInWithEmailAndPassword(auth, email, password);
    return user;
  },
  watchAuthState: (callback) => {
    return onAuthStateChanged(auth, (rawUser) =>
      callback(
        rawUser
          ? {
              id: rawUser.uid,
              email: rawUser.email,
            }
          : null
      )
    );
  },
  signOut: () => {
    return signOut(auth);
  },
};

export const dataClient = {
  watchVisits: (callback) => {
    const db = getFirestore(app);
    return onSnapshot(
      query(
        collection(db, `users/${auth.currentUser.uid}/visits`),
        orderBy("createdAt", "desc")
      ),
      (snapshot) => {
        const visits = snapshot.docs
          .map((doc) => ({
            id: doc.id,
            ...doc.data(),
          }))
          .sort((a, b) => {
            return b.createdAt.toDate() - a.createdAt.toDate();
          });
        callback(visits);
      },
      (error) => {
        console.error(error);
      }
    );
  },
  addVisit: (visit) => {
    const db = getFirestore(app);
    return addDoc(
      collection(db, `users/${auth.currentUser.uid}/visits`),
      visit
    );
  },
};
