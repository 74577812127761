export const UserAvatarPin = ({ position, direction }) => {
  return (
    <group
      position={[position[0], 0.05, position[2]]}
      rotation={[0, Math.PI + -(Math.PI * 2 * direction) / 360, 0]}
    >
      <mesh scale={[0.08, 0.09, 0.05]}>
        <boxGeometry />
        <meshStandardMaterial color="hsl(20, 90%, 55%)" />
      </mesh>
      <mesh position={[0, 0.09, 0]} scale={0.04}>
        <sphereGeometry />
        <meshStandardMaterial color="hsl(20, 90%, 55%)" />
      </mesh>
      <mesh position={[-0.03, 0.033, 0.045]} scale={[0.01, 0.01, 0.045]}>
        <boxGeometry />
        <meshStandardMaterial color="hsl(20, 99%, 58%)" />
      </mesh>
    </group>
  );
};
