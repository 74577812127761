import { useThree } from "@react-three/fiber";
import { Perf } from "r3f-perf";
import { useEffect, useState } from "react";
import { Raycaster, Vector3 } from "three";
import { sculptures } from "../../data/sculptures";

export const DevTools = ({ mapPlaneRef }) => {
  const [duped, setDuped] = useState([]);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    const idCounts = sculptures.reduce((acc, sculpture) => {
      acc[sculpture.id] = (acc[sculpture.id] || 0) + 1;
      return acc;
    }, {});

    const duplicateIds = Object.entries(idCounts)
      .filter(([id, count]) => count > 1)
      .map(([id]) => id);

    const hasChanged =
      duped[duped.length - 1] !== duplicateIds[duplicateIds.length - 1];
    if (hasChanged) {
    }
    setDuped(duplicateIds);

    if (duplicateIds.length > 0 && hasChanged) {
      alert(`Duplicate sculpture IDs found: ${duplicateIds.join(", ")}`);
    }
  });

  const { camera } = useThree();

  const showPerformance = JSON.parse(
    window.localStorage.getItem("showPerf") || "false"
  );

  // log location of mouse click on plane on document click event
  useEffect(() => {
    if (showPerformance) {
      const raycaster = new Raycaster();
      const mouse = new Vector3();
      const onClick = (event) => {
        // calculate mouse position in normalized device coordinates
        // (-1 to +1) for both components
        mouse.x = (event.clientX / window.innerWidth) * 2 - 1;
        mouse.y = -(event.clientY / (window.innerHeight - 57)) * 2 + 1;

        // update the picking ray with the camera and mouse position
        raycaster.setFromCamera(mouse, camera);

        // calculate objects intersecting the picking ray

        const intersects = raycaster.intersectObjects(
          [mapPlaneRef.current],
          true
        );
        if (intersects.length > 0) {
          const point = intersects[0].point;
          const pointAsJsonProperty = `
        position: [${point.x.toFixed(2)}, 0, ${point.z.toFixed(2)}],`;
          navigator.clipboard.writeText(pointAsJsonProperty);
          // This helps with faking location on web:
          // const pointAsJsVariable = `
          // const fakeCoordinates = [${point.x.toFixed(2)}, 0, ${point.z.toFixed(
          //   2
          // )}];`;
          // navigator.clipboard.writeText(pointAsJsVariable);
        }
      };
      document.addEventListener("click", onClick);
      return () => {
        document.removeEventListener("click", onClick);
      };
    }
  }, [camera, showPerformance, mapPlaneRef]);

  useEffect(() => {
    const onKeyDown = (event) => {
      if (event.key === "p") {
        window.localStorage.setItem("showPerf", `${!showPerformance}`);
        window.location.reload();
      }
    };
    document.addEventListener("keydown", onKeyDown);
    return () => {
      document.removeEventListener("keydown", onKeyDown);
    };
  });

  return showPerformance && <Perf position="top-left" />;
};
