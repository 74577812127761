import { IonMenuButton, IonPage } from "@ionic/react";
import { Canvas } from "@react-three/fiber";
import { useEffect, useState } from "react";
import { Route } from "react-router-dom";
import { currentSculptures } from "../../data/sculptures";
import { useVisitsStore } from "../../data/stores/visitsStore";
import { dataClient } from "../../firebase/client";
import { usePrevious } from "../../utils/usePrevious";
import { AppMenu, MENU_GESTURE_WIDTH } from "./AppMenu";
import { Map } from "./Map";
import { SculptureModal } from "./SculptureModal";
import { useUserLocation } from "./location/useUserLocation";
import { useTrackSculptureVisits } from "./location/visits/useTrackSculptureVisits";
import { newVisitToast } from "./newVisitToast";
import { useHistory } from "react-router-dom";

const MapTab = ({ currentUser }) => {
  const history = useHistory();
  const [modalContent, setModalContent] = useState(null);
  const { visits, setVisits } = useVisitsStore();

  const {
    userCurrentCoordinates,
    userDirection,
    isUserInMapRegion,
    isRealLocation,
  } = useUserLocation();
  const previosUserCurrentCoordinates = usePrevious(userCurrentCoordinates);

  useEffect(() => {
    if (currentUser?.id) {
      const stopWatching = dataClient.watchVisits(setVisits);
      return () => {
        stopWatching();
      };
    } else {
      setVisits([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentUser?.id]);

  const nearbySculptures = useTrackSculptureVisits(
    userCurrentCoordinates,
    currentSculptures,
    async (visitedId) => {
      if (!isRealLocation) {
        console.log("not real location, visitedId not stored: ", visitedId);
      }
      const sculpture = currentSculptures.find((s) => s.id === visitedId);
      newVisitToast(sculpture, () => {
        history.push(`/`);
        setModalContent(sculpture);
      });
      if (currentUser && isRealLocation) {
        await dataClient.addVisit({
          sculptureId: visitedId,
          userId: currentUser.id,
          createdAt: new Date(),
        });
      }
    }
  );

  const [cameraControls, setCameraControls] = useState("map");

  useEffect(() => {
    if (
      !previosUserCurrentCoordinates &&
      userCurrentCoordinates &&
      isUserInMapRegion
    ) {
      setCameraControls("orbit");
    } else if (previosUserCurrentCoordinates && !userCurrentCoordinates) {
      setCameraControls("map");
    }
  }, [
    userCurrentCoordinates,
    previosUserCurrentCoordinates,
    isUserInMapRegion,
  ]);

  return (
    <>
      <Route exact path="/">
        <>
          <AppMenu currentUser={currentUser} visits={visits} />
          {modalContent && (
            <SculptureModal
              setModalContent={setModalContent}
              modalContent={modalContent}
              lastVisit={
                visits.find((visit) => visit.sculptureId === modalContent.id)
                  ?.createdAt
              }
            />
          )}
        </>
      </Route>
      <IonPage id="main-content">
        <div style={{ backgroundColor: "hsl(214, 55%, 80%)", height: "100%" }}>
          <Canvas
            shadows
            gl={{ antialias: true }}
            camera={{
              fov: 45,
              near: 0.1,
              far: 200,
              position: [0, 18, 0],
            }}
            style={{ cursor: "grab" }}
            id="map-canvas"
          >
            <Map
              {...{
                modalContent,
                setModalContent,
                userCurrentCoordinates,
                userDirection,
                cameraControls,
                visits,
                nearbySculptures,
              }}
            />
          </Canvas>
          <div
            style={{
              position: "fixed",
              right: 0,
              top: 0,
              height: "100%",
              width: MENU_GESTURE_WIDTH,
            }}
          />
          {userCurrentCoordinates && isUserInMapRegion && (
            <button
              onClick={() =>
                setCameraControls((currentControls) =>
                  currentControls === "map" ? "orbit" : "map"
                )
              }
              style={{
                position: "absolute",
                bottom: 16,
                left: "50%",
                transform: "translateX(-50%)",
                padding: "8px 32px",
                borderRadius: 8,
                fontWeight: "bold",
                textTransform: "uppercase",
                display: "flex",
                alignItems: "stretch",
                verticalAlign: "middle",
                fontSize: 48,
              }}
              title="Toggle camera controls"
              aria-label={
                "Make camera look at " + cameraControls === "map"
                  ? "me"
                  : "whole map"
              }
            >
              {cameraControls === "map" ? "🧍" : "🗺"}
            </button>
          )}
          <Route exact path="/">
            <IonMenuButton
              style={{
                position: "absolute",
                right: 0,
                top: 0,
              }}
              color="light"
              menu="main-menu"
            />
          </Route>
        </div>
      </IonPage>
    </>
  );
};

export default MapTab;
