import { useFrame, useThree } from "@react-three/fiber";
import * as React from "react";
import { OrbitControls as OrbitControlsImpl } from "./OrbitControls";

export const OrbitControls = React.forwardRef(
  (
    {
      makeDefault,
      camera,
      regress,
      domElement,
      enableDamping = true,
      onChange,
      onStart,
      onEnd,
      ...restProps
    },
    ref
  ) => {
    const invalidate = useThree((state) => state.invalidate);
    const defaultCamera = useThree((state) => state.camera);
    const gl = useThree((state) => state.gl);
    const events = useThree((state) => state.events);
    const setEvents = useThree((state) => state.setEvents);
    const set = useThree((state) => state.set);
    const get = useThree((state) => state.get);
    const performance = useThree((state) => state.performance);
    const explCamera = camera || defaultCamera;
    const explDomElement = domElement || events.connected || gl.domElement;
    const controls = React.useMemo(
      () => new OrbitControlsImpl(explCamera),
      [explCamera]
    );

    useFrame(() => {
      if (controls.enabled) controls.update();
    }, -1);

    React.useEffect(() => {
      controls.connect(explDomElement);
      return () => void controls.dispose();
    }, [explDomElement, regress, controls, invalidate]);

    React.useEffect(() => {
      const callback = (e) => {
        invalidate();
        if (regress) performance.regress();
        if (onChange) onChange(e);
      };

      const onStartCb = (e) => {
        if (onStart) onStart(e);
      };

      const onEndCb = (e) => {
        if (onEnd) onEnd(e);
      };

      controls.addEventListener("change", callback);
      controls.addEventListener("start", onStartCb);
      controls.addEventListener("end", onEndCb);

      return () => {
        controls.removeEventListener("start", onStartCb);
        controls.removeEventListener("end", onEndCb);
        controls.removeEventListener("change", callback);
      };
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [onChange, onStart, onEnd, controls, invalidate, setEvents]);

    React.useEffect(() => {
      if (makeDefault) {
        const old = get().controls;
        set({ controls });
        return () => set({ controls: old });
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [makeDefault, controls]);

    return (
      <primitive
        ref={ref}
        object={controls}
        enableDamping={enableDamping}
        {...restProps}
      />
    );
  }
);
